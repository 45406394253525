import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Text, View } from '../../../atoms';
import { Verified } from '../../../atoms/Icon/Icons/Verified';
import classes from './VerifiedPharmacy.module.scss';

type VerifiedPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

export const VerifiedPharmacy = ({ pharmacy, setConfirmationModal, setChosenPharmacy }: VerifiedPharmacyProps) => {
  const navigate = useNavigate();

  const userInformation = useAppSelector(selectUserInformation);
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;

  return (
    <View className={classes.VerifiedPharmacy}>
      <div className={classes.headline}>
        <Text className={classes['title-left']}>{pharmacy.name}</Text>
        <Text className={classes['title-right']}>{pharmacy.city}</Text>
      </div>
      <View key={pharmacy.id} className={`${classes.Details} align-center`}>
        <View className={classes['divider-green']}>
          <View className='row justify-center align-center'>
            <Button
              className={`${classes.button} ${classes['details-button']}`}
              onClick={() => {
                navigate(`/pharmacy-details/${pharmacy.id}`);
              }}
              disabled={false}
            >
              <Text className={classes.text}>Details</Text>
            </Button>
            <Button
              className={`${classes.button} ${classes['pharmacy-button']}`}
              onClick={() => {
                setConfirmationModal(true);
                setChosenPharmacy(pharmacy);
              }}
              disabled={disable}
            >
              <Text className={classes.text}>{disable ? 'Ausgewählte Apotheke' : 'Apotheke wählen'}</Text>
            </Button>
          </View>
        </View>
      </View>
      <View className={classes['rating-row']}>
        <Text className={classes.verified}>
          <Verified color={'#3db384'} styles={{ marginRight: 10 }} />
          Verifizierte Apotheke
        </Text>
        {pharmacy.reviewsRef ? (
          <a target='_blank' href={`https://www.reviews.io/company-reviews/store/${pharmacy.reviewsRef}`}>
            <img src={`https://media.reviews.co.uk/badge/${pharmacy.reviewsRef}.png`} width='100' />
          </a>
        ) : null}
      </View>
    </View>
  );
};
