// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PharmacyService from '../../../services/Pharmacy.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectPharmacies, setPharmacies } from '../../../store/reducers/pharmacyReducer';
import type { ReducerAction } from '../../../types/navigationIndex.types';
import type { Pharmacy } from '../../../types/ninox.types';
import { Headline, Icon, Loading, View } from '../../atoms';
import { Header } from '../../organisms';
import { PremiumPharmacy } from './PharmacyComponent/PremiumPharmacy';
import { StandardPharmacy } from './PharmacyComponent/StandardPharmacy';
import { VerifiedPharmacy } from './PharmacyComponent/VerifiedPharmacy';
import { PharmacyConfirmationModal } from './PharmacyConfirmation';
import classes from './PharmacyList.module.scss';

type PharmacyListProps = {
  setCurrentPage: React.Dispatch<ReducerAction>;
};

export const PharmacyList = ({ setCurrentPage }: PharmacyListProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const pharmaciesRedux = useAppSelector(selectPharmacies);

  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(true);

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [chosenPharmacy, setChosenPharmacy] = useState<Pharmacy>({
    id: 0,
    name: '',
    category: 0,
    isPublic: false,
    descriptionShort: '',
    image: '',
    isVerified: false,
    rating: 0,
    city: '',
    postcode: '',
    street: '',
    houseNumber: '',
    phone: '',
    email: '',
    website: '',
    patientPharmacyrelation: [],
    reviewsRef: '',
  });

  useEffect(() => {
    if (pharmaciesRedux.length === 0) {
      setLoading(true);

      (async () => {
        const pharmaciesResult = await PharmacyService.getPharmacies('1');
        dispatch(setPharmacies(pharmaciesResult.data));
        setLoading(false);
      })();
    }
  }, []);

  const loadMorePharmacies = async () => {
    setLoading(true);

    const pharmaciesVerified = await PharmacyService.getPharmacies('2');
    const pharmaciesStandard = await PharmacyService.getPharmacies('3');

    const allPharmacies = [...pharmaciesRedux, ...pharmaciesVerified.data, ...pharmaciesStandard.data];
    dispatch(setPharmacies(allPharmacies));

    setShowButton(false);
    setLoading(false);
  };

  const Pharmacies = () => (
    <>
      {pharmaciesRedux.length > 0 &&
        pharmaciesRedux.map((pharmacy) =>
          pharmacy.category === 1 ? (
            <PremiumPharmacy
              key={pharmacy.id}
              pharmacy={pharmacy}
              setConfirmationModal={setConfirmationModal}
              setChosenPharmacy={setChosenPharmacy}
            />
          ) : pharmacy.category === 2 ? (
            <VerifiedPharmacy
              key={pharmacy.id}
              pharmacy={pharmacy}
              setConfirmationModal={setConfirmationModal}
              setChosenPharmacy={setChosenPharmacy}
            />
          ) : (
            <StandardPharmacy
              key={pharmacy.id}
              pharmacy={pharmacy}
              setConfirmationModal={setConfirmationModal}
              setChosenPharmacy={setChosenPharmacy}
            />
          )
        )}
    </>
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <Header />
      <PharmacyConfirmationModal
        pharmacy={chosenPharmacy}
        confirmationModal={confirmationModal}
        setConfirmationModal={setConfirmationModal}
        setCurrentPage={setCurrentPage}
      />
      <View className={classes.PharmacyList}>
        <Icon icon='pharmacy' size={90} />
        <View className={classes['info-text']}>
          <Headline level={3}>Bitte wählen Sie Ihre Apotheke</Headline>
          <Headline level={4}>Bitte wählen Sie aus der folgenden Liste Ihre gewünschte Apotheke.</Headline>
        </View>
        <div className={classes['bigger-width']}>
          <Pharmacies />
        </div>
        {showButton && (
          <div className={classes['more-pharmacies-link']} onClick={() => loadMorePharmacies()}>
            Weitere Apotheken
          </div>
        )}
        <div className={classes['back-button']} onClick={() => navigate('/my-pharmacy')}>
          &#8592; Zurück
        </div>
      </View>
    </React.Fragment>
  );
};
