import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Button, Headline } from '../../../atoms';
import classes from './SuccessPage.module.scss';

const cx = classNames.bind(classes);

export function SuccessPage() {
  const navigate = useNavigate();

  return (
    <div className={cx('SuccessPage')}>
      <Headline level={3}>Vielen Dank</Headline>
      <div className={cx('info-text')}>Ihr Folgerezeptantrag wurde erfolgreich abgeschickt.</div>
      <div className={cx('button-container')}>
        <Button onClick={() => navigate('/')}>Zum Dashboard</Button>
      </div>
    </div>
  );
}
