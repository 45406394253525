import type { ReactNode } from 'react';
import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { LoadTerms } from '../components/atoms/LoadTerms/LoadTerms';
import { CheckAuth } from '../components/molecules/CheckAuth/CheckAuth';
import { PrivateRoute } from '../components/molecules/PrivateRoute/PrivateRoute';
import {
  Appointment,
  AppointmentChoice,
  ConfirmTerms,
  Contact,
  Dashboard,
  Documents,
  Error,
  FollowUpPrescription,
  ForgotPassword,
  Login,
  MyAccount,
  MyPharmacy,
  OfflineAppointment,
  OfflineAppointmentSuccess,
  OnlineAppointment,
  OnlineFirstAppointment,
  PatientenTracking,
  PharmacyDetails,
  PharmacyList,
  PrivacyPolicy,
  QuestionnaireStep1,
  QuestionnaireStep2,
  QuestionnaireStep3,
  QuestionnaireStep4,
  QuestionnaireStep5,
  QuestionnaireStep6,
  QuestionnaireStep7,
  QuestionnaireStep8,
  RegisterProcedure,
  RegisterSuccess,
  ResetPassword,
  TermsAndConditions,
  TrackingSuccess,
  UpdatePersonalData,
} from '../components/pages';
import { FollowUpPrescriptionSuccess } from '../components/pages/FollowUpPrescription/FollowUpPrescriptionSuccess';
import { OfflineFirstAppointment } from '../components/pages/OfflineFirstAppointment/OfflineFirstAppointment';
import { OfflineFirstAppointmentSuccess } from '../components/pages/OfflineFirstAppointment/OfflineFirstAppointmentSuccess';
import { OnlineAppointmentSuccess } from '../components/pages/OnlineAppointment/OnlineAppointmentSuccess';
import { UnavailableAppointment } from '../components/pages/OnlineAppointment/UnavailableAppointment';
import { OnlineFirstAppointmentSuccess } from '../components/pages/OnlineFirstAppointment/OnlineFirstAppointmentSuccess';
import { SetPassword } from '../components/pages/SetPassword/SetPassword';
import { initialState, reactReducer } from '../helper/NavigationReducer';

type RouterProps = {
  children?: ReactNode;
};

export function Router({ children }: RouterProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, reactDispatch] = useReducer(reactReducer, initialState);

  function ScrollUp(): JSX.Element {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return <React.Fragment></React.Fragment>;
  }

  return (
    <BrowserRouter>
      <ScrollUp />
      {children}
      <LoadTerms />
      <Routes>
        <Route path='/' element={<Navigate to='/dashboard' />} />
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<RegisterProcedure />} />
        <Route path='/register/success' element={<RegisterSuccess />} />
        <Route
          path='/my-account'
          element={
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          }
        />
        <Route
          path='/update-personal-data'
          element={
            <PrivateRoute>
              <UpdatePersonalData />
            </PrivateRoute>
          }
        />
        <Route
          path='/questionnaire'
          element={
            <PrivateRoute>
              <Outlet />
            </PrivateRoute>
          }
        >
          <Route path='' element={<>Fragebogen</>} />
          <Route path='step-1' element={<QuestionnaireStep1 />} />
          <Route path='step-2' element={<QuestionnaireStep2 />} />
          <Route path='step-3' element={<QuestionnaireStep3 />} />
          <Route path='step-4' element={<QuestionnaireStep4 />} />
          <Route path='step-5' element={<QuestionnaireStep5 />} />
          <Route path='step-6' element={<QuestionnaireStep6 />} />
          <Route path='step-7' element={<QuestionnaireStep7 />} />
          <Route path='step-8' element={<QuestionnaireStep8 />} />
        </Route>
        <Route
          path='/patienten-tracking'
          element={
            <CheckAuth>
              <PatientenTracking />
            </CheckAuth>
          }
        />
        <Route
          path='/patienten-tracking/success'
          element={
            <CheckAuth>
              <TrackingSuccess />
            </CheckAuth>
          }
        />
        <Route
          path='/my-pharmacy'
          element={
            <PrivateRoute>
              <MyPharmacy />
            </PrivateRoute>
          }
        />
        <Route
          path='/pharmacy-list'
          element={
            <PrivateRoute>
              <PharmacyList setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route
          path='/pharmacy-details/:pharmacyId'
          element={
            <PrivateRoute>
              <PharmacyDetails setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/set-password' element={<SetPassword />} />
        <Route
          path='/profile/documents'
          element={
            <PrivateRoute>
              <Documents />
            </PrivateRoute>
          }
        />
        <Route
          path='/terms-and-conditions'
          element={
            <PrivateRoute>
              <TermsAndConditions />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/appointment-choice'
          element={
            <PrivateRoute>
              <AppointmentChoice />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/online-appointment'
          element={
            <PrivateRoute>
              <OnlineAppointment setCurrentPage={reactDispatch} currentPage={state.onlineAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/unavailable-appointment'
          element={
            <PrivateRoute>
              <UnavailableAppointment />
            </PrivateRoute>
          }
        />
        <Route
          path='/appointment-success'
          element={
            <PrivateRoute>
              <OnlineAppointmentSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path='/appointment/:appointmentId'
          element={
            <PrivateRoute>
              <Appointment />
            </PrivateRoute>
          }
        />
        <Route
          path='/privacy-policy'
          element={
            <PrivateRoute>
              <PrivacyPolicy />
            </PrivateRoute>
          }
        />
        <Route
          path='/confirm-terms'
          element={
            <PrivateRoute>
              <ConfirmTerms />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/online-first-appointment'
          element={
            <PrivateRoute>
              <OnlineFirstAppointment setCurrentPage={reactDispatch} currentPage={state.onlineFirstAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/first-appointment-success'
          element={
            <PrivateRoute>
              <OnlineFirstAppointmentSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/offline-first-appointment'
          element={
            <PrivateRoute>
              <OfflineFirstAppointment
                setCurrentPage={reactDispatch}
                currentPage={state.offlineFirstAppointmentIndex}
              />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile/offline-appointment'
          element={
            <PrivateRoute>
              <OfflineAppointment setCurrentPage={reactDispatch} currentPage={state.offlineAppointmentIndex} />
            </PrivateRoute>
          }
        />
        <Route
          path='/offline-first-appointment-success'
          element={
            <PrivateRoute>
              <OfflineFirstAppointmentSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path='/offline-appointment-success'
          element={
            <PrivateRoute>
              <OfflineAppointmentSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path='/follow-up-prescription'
          element={
            <PrivateRoute>
              <FollowUpPrescription currentPage={state.followUpPrescriptionsIndex} setCurrentPage={reactDispatch} />
            </PrivateRoute>
          }
        />
        <Route
          path='/follow-up-prescription-success'
          element={
            <PrivateRoute>
              <FollowUpPrescriptionSuccess />
            </PrivateRoute>
          }
        />
        <Route
          path='/contact'
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path='/error'
          element={
            <CheckAuth>
              <Error />
            </CheckAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
