import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Image, Text, View } from '../../../atoms';
import { PremiumCheck } from '../../../atoms/Icon/Icons/PremiumCheck';
import classes from './PremiumPharmacy.module.scss';

type PremiumPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

let scriptAdded = false;

export const PremiumPharmacy = ({
  pharmacy,
  setConfirmationModal,
  setChosenPharmacy,
}: PremiumPharmacyProps): JSX.Element => {
  const navigate = useNavigate();

  const userInformation = useAppSelector(selectUserInformation);
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;

  useEffect(() => {
    if (pharmacy.reviewsRef) {
      if (!scriptAdded) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://widget.reviews.io/badge-modern/dist.js';
        document.head.appendChild(s);
        scriptAdded = true;
      }

      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.innerHTML = `try { window.reviewsBadgeModern('badge-${pharmacy.id}', { store: '${pharmacy.reviewsRef}', primaryClr: '#000000', starsClr: '#FFCC00', translateReviews: ' Bewertungen', translateAverage: ' Durchschnitt', translateDecimal: '.', }); } catch (err) { console.error('Error loading reviews', err); }`;
      document.head.appendChild(s);

      return () => {
        document.head.removeChild(s);
      };
    }

    return undefined;
  }, [pharmacy]);

  return (
    <View className={classes.PremiumPharmacy}>
      <Image
        className={classes.image}
        tintColor={'#fff'}
        source={{
          height: 60,
          width: 250,
          uri: `data:image/jpg;base64,${pharmacy.image}`,
        }}
      />
      <View className={classes.Details} key={pharmacy.id}>
        {pharmacy.descriptionShort && pharmacy.descriptionShort.trim().length ? (
          <Text className={classes.description} children={pharmacy.descriptionShort} />
        ) : null}
        <View className={classes.divider}>
          <View className={`row justify-center ${classes['button-row']}`}>
            <Button
              className={`${classes.button} ${classes['button-details']}`}
              onClick={() => {
                navigate(`/pharmacy-details/${pharmacy.id}`);
              }}
              disabled={false}
            >
              <Text className={classes['button-text']}>Details</Text>
            </Button>
            <Button
              className={`${classes.button} ${classes['button-choose-pharmacy']}`}
              onClick={() => {
                setConfirmationModal(true);
                setChosenPharmacy(pharmacy);
              }}
              disabled={disable}
            >
              <Text className={classes['button-text']}>{disable ? 'Ausgewählte Apotheke' : 'Apotheke wählen'}</Text>
            </Button>
          </View>
        </View>
      </View>
      <View className={classes['rating-row']}>
        <Text className={classes['premium-check']}>
          <PremiumCheck color={'#e3e3e3'} style={{ marginRight: 10 }} />
          Beliebte Apotheke
        </Text>
        {pharmacy.reviewsRef ? <div id={`badge-${pharmacy.id}`} style={{ maxWidth: '120px' }}></div> : null}
      </View>
    </View>
  );
};
