import React from 'react';
import { Header } from '../../organisms/Header/Header';
import { SuccessPage } from './Steps/SuccessPage';

export function FollowUpPrescriptionSuccess() {
  return (
    <React.Fragment>
      <Header />
      <SuccessPage />
    </React.Fragment>
  );
}
