import type { CSSProperties, MouseEventHandler } from 'react';
import React from 'react';
import { mergeStyles } from '../../../helper/UtilityFunctions';
import styles from './Button.module.scss';

type ButtonProps = {
  children?: React.ReactNode;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  secondary?: boolean;
  small?: boolean;
  inlineBlock?: boolean;
  style?: CSSProperties | CSSProperties[];
  className?: string;
};

export function Button(props: ButtonProps) {
  let effectiveStyle;
  if (Array.isArray(props.style)) {
    effectiveStyle = mergeStyles(props.style);
  } else {
    effectiveStyle = props.style;
  }

  let effectiveClasses = styles.Button;

  if (props.className) {
    effectiveClasses += ` ${props.className}`;
  }

  if (props.small) {
    effectiveClasses += ` ${styles.small}`;
  }

  if (props.inlineBlock) {
    effectiveClasses += ` ${styles.inlineBlock}`;
  }

  if (props.disabled) {
    effectiveClasses += ` ${styles.disabled}`;
  }

  if (props.secondary) {
    effectiveClasses += ` ${styles.secondary}`;
  }

  return (
    <div style={effectiveStyle} className={effectiveClasses} onClick={!props.disabled ? props.onClick : undefined}>
      {props.children}
    </div>
  );
}

type LinkButtonProps = {
  href: string;
  target?: string;
  style?: CSSProperties;
  children: React.ReactNode;
};

export function LinkButton({ href, target, style, children }: LinkButtonProps): JSX.Element {
  return (
    <a className={styles.Button} href={href} style={style} target={target}>
      {children}
    </a>
  );
}
