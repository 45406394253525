import React from 'react';
import { format, setDefaultOptions } from 'date-fns';
import { de } from 'date-fns/locale';
import { Icon, LinkButton } from '../../../atoms';
import classes from './StageElements.module.scss';

type FirstAppointmentBookedProps = {
  appointmentInformation: any;
};

export const FirstAppointmentBooked = (props: FirstAppointmentBookedProps) => {
  const { appointmentInformation } = props;
  const appointmentStartDate = new Date(appointmentInformation.startDate);
  const appointmentId = appointmentInformation.id;

  // set locale for date-fns
  setDefaultOptions({ locale: de });

  const startDate = new Date(appointmentInformation.startDate);
  const endDate = new Date(appointmentInformation.endDate);
  const differenceInMs = endDate.getTime() - startDate.getTime();
  const appointmentDuration = differenceInMs / 60000;

  return (
    <>
      <div className={classes.CircleWithIcon}>
        <div className={classes.CalendarDayContainer}>
          <Icon icon='calendar' size={70} color={'white'} />
          <div className={classes.CalendarDayNumber}>{format(appointmentStartDate, 'd')}</div>
        </div>
        <div className={classes.CircleText}>
          Termin
          <br />
          Erstgespräch
        </div>
      </div>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionTitle}>{format(appointmentStartDate, 'PPPp')}&nbsp;Uhr</div>
        <div className={`${classes.DescriptionText} ${classes.DescriptionTextSemiBold}`}>
          Arzt: {`${appointmentInformation.doctorTitle} ${appointmentInformation.doctorName}`}
          <br />
          Dauer: {appointmentDuration} Minuten
        </div>
        {/* show practice location only for offline appointments */}
        {appointmentInformation.type === 2 && (
          <div className={classes.DescriptionText}>
            <span className={classes.DescriptionTextSemiBold}>Therapiezentrum</span>
            <br />
            {appointmentInformation.practice.name}
            <br />
            {appointmentInformation.practice.address[0].street} {appointmentInformation.practice.address[0].houseNumber}
            <br />
            {appointmentInformation.practice.address[0].postcode} {appointmentInformation.practice.address[0].city}
          </div>
        )}
        <div className={classes.DescriptionText}>
          <span className={classes.DescriptionTextSemiBold}>Bitte beachten Sie:</span>
          <br />
          Der Termin für Ihr Erstgespräch kann nur stattfinden, wenn Sie uns alle notwendigen Dokumente mindestens 24
          Stunden vor Ihrem Termin übermittelt haben. Details dazu finden Sie in der Bestätigungs-Mail Ihres Termins.
        </div>
        {appointmentInformation.type === 1 && (
          <div className={classes.ActionButtons}>
            <LinkButton href={`/appointment/${appointmentId}`} target='_blank'>
              Zur Videosprechstunde
            </LinkButton>
          </div>
        )}
      </div>
    </>
  );
};
