import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import { selectUserInformation } from '../../../../store/reducers';
import type { Pharmacy } from '../../../../types/ninox.types';
import { Button, Text, View } from '../../../atoms';
import classes from './StandardPharmacy.module.scss';

type StandardPharmacyProps = {
  pharmacy: Pharmacy;
  setConfirmationModal: (value: boolean) => void;
  setChosenPharmacy: (pharmacy: Pharmacy) => void;
};

export const StandardPharmacy = ({ pharmacy, setConfirmationModal, setChosenPharmacy }: StandardPharmacyProps) => {
  const userInformation = useAppSelector(selectUserInformation);
  const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;
  const navigate = useNavigate();

  return (
    <View className={classes.StandardPharmacy}>
      <div className={classes.headline}>
        <Text className={classes['title-left']}>{pharmacy.name}</Text>
        <Text className={classes['title-right']}>{pharmacy.city}</Text>
      </div>
      <View key={pharmacy.id} className={`${classes.Details} align-center`}>
        <View className={classes['button-row']}>
          <Button
            className={`${classes.button} ${classes['details-button']}`}
            onClick={() => {
              navigate(`/pharmacy-details/${pharmacy.id}`);
            }}
            disabled={false}
          >
            <Text className={classes.text}>Details</Text>
          </Button>
          <Button
            className={`${classes.button} ${classes['pharmacy-button']}`}
            onClick={() => {
              setConfirmationModal(true);
              setChosenPharmacy(pharmacy);
            }}
            disabled={disable}
          >
            <Text className={classes.text}>{disable ? 'Ausgewählte Apotheke' : 'Apotheke wählen'}</Text>
          </Button>
        </View>
      </View>
    </View>
  );
};
